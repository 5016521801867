import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { motion } from "framer-motion"
import Header from '../components/Header'
import { Footer } from '../components/Footer'
import SEO from '../components/SEO'

const Tour = ({ data }: any) => {
	const tour = data.allTour.edges[0].node

	useEffect(() => {
		const scrollContent: any = document.getElementById('main')
		scrollContent?.scrollIntoView()
	}, [])

	const [imageLoading, setImageLoading] = useState(true);
	// const [pulsing, setPulsing] = useState(true);

	const imageLoaded = () => {
		setImageLoading(false);
		// setTimeout(() => setPulsing(false), 600);
	};

	return (
		<>
			<SEO title={tour.seo.title} description={tour.seo.description} />

			<div className="container">

				<Header/>

				<div className="row justify-content-md-center">
					<div className="col col-md-9">
						<div className="single-post">
							<div className="single-post-header">
								<div className="row">
									<div className="col-sm-6">
										<motion.h1 
											layoutId={`title-${tour.tourId}`}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											transition={{ delay: 0, duration: 1 }} 
											className="single-post-title">
												{tour.title}
										</motion.h1>
									</div>
									<div className="col-sm-6">
										<div className="single-post-image">
											<motion.img
												layoutId={`featuredImage-${tour.tourId}`}
												initial={{ opacity: 0 }}
												animate={{ opacity: imageLoading ? 0 : 1 }}
												transition={{ delay: 0, duration: 1 }} 
												onLoad={imageLoaded}
												src={tour.imageUrl} 
												/>
										</div>
									</div>
								</div>
								<div className="single-post-abs m-hidden">
									<div className="abs">
										<motion.h3 initial={{ opacity: 0, x: 150 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.6, delay: 0.8 }}>TOUR</motion.h3>
									</div>
								</div>
							</div>

							<h4>{tour.subTitle}</h4>

							<motion.div 
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ delay: 0.2 }} 
								className="single-post-content">
									<div dangerouslySetInnerHTML={{ __html: tour.content }} />
							</motion.div>

							{/* <div className="single-post-tags">
								<p><b>Tags:</b></p>
								{Tags(project)}
							</div> */}
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	)
}

export default Tour

export const query = graphql`
	query SingleTourQuery($tourId: String!) {
		allTour(filter: { tourId: { eq: $tourId } }) {
			edges {
				node {
					tourId
					title
					subTitle
					content
					imageUrl
					order
					seo { url, title, description }
				}
			}
		}
	}
`